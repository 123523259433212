.backg {
    background-image: url("../../assets/img.jpg");
}

.App {
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 96vh;
    color: white;
    font-size: 18px;
    display: grid;
    place-items: center;

}



.Movies select {
    appearance: none;
    background: white;
    font-size: 14px;
    margin-left: 12px;
    border-radius: 4px;
    padding: 6px 24px;
}

.ShowCase {

    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    background: #202429;
    padding: 12px;
    border-radius: 4px;
    color: #7e7e7e;
}

.ShowCase li {
    margin: 0 12px;
}

.Cinema {
    margin-bottom: 18px;
    perspective: 400px;
    display: grid;
    place-items: center;
    grid-gap: 24px;
}

.Cinema .screen {
    height: 70px;
    background: white;
    width: 100%;
    transform: rotateX(-30deg) scale(1.1);
    box-shadow: 0 3px 10px 2px;
}

.Cinema .seats {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(8, min-content);
    align-items: center;
}

.Cinema .seats .seat:nth-of-type(8n + 2) {
    margin-right: 12px;
}

.Cinema .seats .seat:nth-of-type(8n + 6) {
    margin-right: 12px;
}

.Cinema .seats .seat:not(.occupied):hover,
.Cinema .seats .seat:not(.occupied):focus {
    cursor: pointer;
    background: #c1eac5;
    transform: scale(1.2);
}

.Cinema .seats .seat.selected::after {
    content: '';
    position: absolute;
    top: 0;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: transparent;
    border: 1px solid #e03444;
    animation: show-off 0.8s;
    visibility: hidden;
}



.seat {
    display: inline-block;
    background: #626262;
    width: 16px;
    height: 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: transform 0.3s ease-in-out;

    position: relative;
    top: 1px;
}

.seat.selected {
    background: #e03444 !important;
}

.seat.occupied {
    background: #cfcfcf;
}

.info .count {
    color: #e03444;
}

.info .total {
    color: #e03444;
}